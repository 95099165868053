.dashcontainer {
    .card {
      background: #ececec;
      border-radius: 20px;
      box-shadow: 8px 8px 15px #a7a7a7, -8px -8px 15px #ffffff;
      padding: 20px;
      margin: 5% 20%;
      text-align: center;
      width: auto;
      max-width: 100%; // Adjust based on your preferenc
      height: 100%;
    
  
      .toggle-wrapper {
        display: flex;
        border-radius: .5em;
        padding: .135em;
        font-size: 1.5em;
      }
  
      .toggle-checkbox {
        appearance: none;
        position: absolute;
        z-index: 1;
        border-radius: inherit;
        width: 15.25%;
        font: inherit;
        opacity: 0;
        cursor: pointer;
        border: none;
        padding: 15px 1px;
        margin-top: 25px ;
      }
  
      .toggle-container {
        display: flex;
        align-items: center;
        position: relative;
        border-radius: .375em;
        width: 3em;
        height: 1.5em;
        background-color: #e8e8e8;
        box-shadow: inset 0 0 .0625em .125em rgb(255 255 255 / .2), inset 0 .0625em .125em rgb(0 0 0 / .4);
        transition: background-color .4s linear;
        margin-top: 25px;
      }
  
      .toggle-checkbox:checked + .toggle-container {
        background-color: #f3b519;
      }
  
      .toggle-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: .0625em;
        border-radius: .3125em;
        width: 1.375em;
        height: 1.375em;
        background-color: #e8e8e8;
        box-shadow: inset 0 -.0625em .0625em .125em rgb(0 0 0 / .1), inset 0 -.125em .0625em rgb(0 0 0 / .2), inset 0 .1875em .0625em rgb(255 255 255 / .3), 0 .125em .125em rgb(0 0 0 / .5);
        transition: left .4s;
      }
  
      .toggle-checkbox:checked + .toggle-container > .toggle-button {
        left: 1.5625em;
      }
  
      .graph-value {
        background: #c4c4c4;
        border-radius: 10px 0 0 10px;
        height: 100%;
        width: 50%;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.5s ease-in-out;
      }
  
      .measurement-box {
        background: #ececec;
        border-radius: 10px;
        box-shadow: inset 4px 4px 8px #a7a7a7, inset -4px -4px 8px #ffffff;
        padding: 10px;
        margin-bottom: 10px;
        width: auto;
      }
  
      .graph-bar {
        background: repeating-linear-gradient(90deg, #fff, #fff 10px, #ccc 10px, #ccc 11px);
        border-radius: 5px;
        height: 20px;
        width: 100%;
        margin: 5px 0;
        position: relative;
        overflow: hidden;
      }
  
      .measurements-container {
        display: grid;
        grid-template-columns: repeat(1, 2fr);
        gap: 20px;
        margin-bottom: 10px;
        height: 500px;
      }
    }
  
    @media (max-width: 600px) {
      .card {
        margin: 10px;
        padding: 15px;
        margin-top: 100px;
        margin-bottom: 75px;
        max-width: 100%; // Adjust based on your preference
        height: 100%;
      }
    }
  }
  