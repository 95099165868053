.mode-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa; // Enhanced soft gray background
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); // Slightly darker shadow for more depth

    .mode-switches {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .switch-button {
            flex-grow: 1;
            background-color: #e0e0e0; // Neutral button background
            border: 2px solid #ccc; // Add border to inactive state for clarity
            border-radius: 4px;
            padding: 12px 20px; // Slightly larger padding for better touch interaction
            margin: 0 10px;
            font-weight: bold;
            color: #333;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.3s ease-in-out, color 0.2s;

            &.active {
                color: #fff;
                border: 2px solid transparent; // Smooth transition to active state
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25); // More pronounced shadow for active state
            }

            &:hover {
                background-color: #d0d0d0; // Slightly lighter hover state
                transform: translateY(-3px); // More noticeable movement on hover
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); // Stronger hover shadow for 3D effect
            }

            &.buy {
                &.active, &:hover {
                    background-color: #3a9548; // A richer shade of green for better visibility
                }
            }

            &.sell {
                &.active, &:hover {
                    background-color: #007299; // A deeper blue for a more premium look
                }
            }

            &.off {
                &.active, &:hover {
                    background-color: #e33e31; // A warmer red to catch attention
                }
            }
        }
    }

    .sellers-list {
        width: 100%;
        
        .seller-info {
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #bbb; // Lighter border color for modern feel
            padding: 16px; // Uniform padding
            margin-bottom: 12px; // Uniform margin
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16); // Slightly adjusted shadow

            h3 {
                margin-top: 0;
                color: #333;
            }

            ul {
                padding-left: 20px;
            }

            .toggle-device {
                background-color: #ff9800; // Consistent orange
                border: none;
                padding: 10px 18px; // Larger padding for easier click
                border-radius: 4px;
                margin-top: 12px; // Increased spacing
                cursor: pointer;
                transition: background-color 0.25s; // Smooth color transition

                &:hover {
                    background-color: #e68900; // Darker hover for contrast
                }
            }
        }
    }

    .no-sellers {
        color: #666;
        font-style: italic;
        font-size: 14px; // Slightly larger font for readability
    }
}
