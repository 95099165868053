.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #f8f9fa;
    padding: 0 20px;
    margin-top: 10px;
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.logo-image {
    height: 60px; // Adjust as needed
}

.profile-navbar {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.profile-navbar-image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.profile-placeholder {
    width: 55px;
    height: 55px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
}
