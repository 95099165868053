.auth-form {
    background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;

    @import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

/* Custom Checkbox Style */
.custom-checkbox-container {
	display: flex;
	align-items: center;
	font-family: 'Roboto', sans-serif; /* Cool font */
	margin: 15px 0; /* Adds some space around the component */
	margin-right: 27px
  }
  
  .custom-checkbox-container input[type="checkbox"] {
	accent-color: #4CAF50; /* Change this color to change the checkbox color */
	width: 16px; /* Smaller checkbox */
	height: 16px; /* Smaller checkbox */
  }
  
  .custom-checkbox-container label {
	color: #333; /* Initial label color */
	font-size: 14px; /* Smaller font size */
	padding: 5px 10px; /* Padding around the label text */
	border-radius: 5px; /* Slight rounding of corners */
	transition: background-color 0.3s, color 0.3s; /* Smooth transition for color change */
  }
  
  /* Styling when checkbox is checked */
  .custom-checkbox-container input[type="checkbox"]:checked + label {
	background-color: #4CAF50; /* Background color when checked */
	color: white; /* Label text color when checked */
  }
  

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
    color: black;
}

span {
	font-size: 12px;
}

a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	border-radius: 20px;
	border: 1px solid #000000;
	background-color: #ffffff;
	color: #000000;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #000000;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}


.logininput {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.createinput {
	background-color: #eee;
	border: none;
	padding: 9px 12px;
	margin: 8px 0;
	width: 100%;
}

.container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #14f9d3;
	/* background: -webkit-linear-gradient(to right, #2402ff, #17d206); */
	background: linear-gradient(to right, #bbdee2, #ffffcd);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

/* .social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
} */

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: rgb(251, 4, 4);
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

.social {
    float: right;
    max-width: 320px;
    display: flex;
    padding: 0.5rem 1.4rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    vertical-align: middle;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.25);
    gap: 0.75rem;
    color: #c4d2dc;
    background-color: #19242b;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0, 0.87, 0.12, 1);
  }
  
  .social:hover {
    transform: scale(1.025);
  }
  
  .social:active {
    transform: scale(0.975);
  }
  
  button svg {
    height: 24px;
    width: auto;
  }
  
/* Existing CSS above */

/* iPhone-specific adjustments */
@media only screen and (max-width: 375px) { /* iPhone X, XS, 11 Pro, etc., in portrait */
    h1, h2 {
        font-size: 18px; /* Smaller font sizes for headings */
    }
     a, input, button {
        font-size: 12px; /* Adjust font size for readability */
    }
    button {
        padding: 5px 50px; /* Larger touch area for buttons */
    }
    input {
       width: 150%;
    }
    .container, .overlay-container {
        width: 400px; /* Full width containers for smaller screens */
        padding: 0 20px; /* Padding adjustment for screen edge */
    }
   .overlay-panel {
    width: 78%;
    }
    p{
        font-size: 10px;
    }
}

@media only screen and (max-width: 414px) { /* Larger iPhones in portrait (iPhone 6+, 7+, 8+, X, XR, XS Max, 11, 11 Pro Max) */
    /* Adjustments similar to 375px media query but tailored for slightly larger screens */
    h1, h2 {
        font-size: 18px; /* Smaller font sizes for headings */
    }
    p, a, input, button {
        font-size: 16px; /* Adjust font size for readability */
    }
    button {
        padding: 5px 50px; /* Larger touch area for buttons */
    }
    input {
       width: 150%;
    }
    .container, .overlay-container {
        width: 400px; /* Full width containers for smaller screens */
        padding: 0 20px; /* Padding adjustment for screen edge */
    }
   .overlay-panel {
    width: 78%;
    }
}

@media only screen and (max-width: 812px) and (orientation: landscape) { /* iPhones with larger screens in landscape */
    body {
        flex-direction: row; /* Adjust main axis for landscape orientation */
        justify-content: space-around; /* Space out elements more */
    }
    .container {
        max-width: 90%; /* Limit max-width for better spacing */
        margin: 20px; /* Add margin around container for better spacing */
    }
    /* Adjust font sizes and paddings for landscape orientation */
    h1, h2, p, a, button, input {
        font-size: 16px; /* Decrease font size for landscape */
    }
    button, input {
        padding: 12px 30px; /* Adjust padding for comfort in landscape */
    }
}
    
  }
  