.navBar2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    height: 12vh;
    width: 100%; // Make width responsive
  
    * {
      padding: 0;
      margin: 0;
    }

    .logo-image {
      height: 60px;
      display: flex;
      margin: 20px 1250px 0 0;
  }
    
    body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      font-family: Hack, monospace;
    }
    
    div {
      color: #727272;
      text-align: center;
    }
    
    p {
      margin: -5px;
      font-size: 40px;
      color: #ccc;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 1s ease-in-out;
      position: relative;
      margin-right: 77rem;
    
      &::before {
        content: attr(data-item);
        transition: all 1s ease-in-out;
        color: #8254ff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        overflow: hidden;
      }
    
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
    
    nav {
      margin: -35px;
      padding: -16px;
      .menuItems {
        list-style: none;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    
        li {
          margin: 50px;
    
          a {
            text-decoration: none;
            color: #8f8f8f;
            font-size: 24px;
            font-weight: 400;
            transition: all 0.5s ease-in-out;
            position: relative;
            text-transform: uppercase;
    
            &::before {
              content: attr(data-item);
              transition: 0.5s;
              color: #8254ff;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 0;
              overflow: hidden;
            }
    
            &:hover {
              &::before {
                width: 100%;
                transition: all 0.5s ease-in-out;
              }
            }
          }
        }
      }
      @media (min-width: 1024px) {
        p {
          margin-right: 10rem; // Example for larger screens, adjust as needed
       
        }
      }  
    }
  }
  