@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gaugeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 80px 100px;
  overflow: hidden;
  background: #ececec;
}

.gaugebox {
  position: relative;
  width: 400px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px
}

.gaugebox::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(#fff, #fff, #e3e3e3);
  filter: blur(1px);
  z-index: 1;
}

.gaugebox::after {
  content: '';
  position: absolute;
  top: 1px;
  right: -1px;
  width: 20px;
  height: 100%;
  background: #9d9d9d;
  filter: blur(1px);
  z-index: 1;
}

.gaugebox .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #eee;
}

.gaugebox .shadow::before {
  content: '';
  position: absolute;
  top: 0;
  left: calc(100% + 5px);
  width: 100%;
  background: linear-gradient(rgba(0,0,0,0.075), transparent);
  transform: skewX(45deg);
}

.gaugebox .shadow::after {
  content: '';
  position: absolute;
  bottom: -300%;
  left: calc(100% + 15px);
  width: 100%;
  height: 200%;
  background: linear-gradient(rgba(0,0,0,0.075), transparent);
  transform: skewX(45deg);
}

.gaugebox .content {
  position: relative;
  width: 500px;
  height: 100%;
  background: linear-gradient(#dbdae1, #a3aaba);
  box-shadow: 5px 5px 5px rgba(0,0,0,0.1),
    15px 15px 15px rgba(0,0,0,0.1),
    20px 20px 20px rgba(0,0,0,0.1),
    50px 50px 50px rgba(0,0,0,0.1),
    inset 3px 3px 2px #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gaugebox .content .percent {
  --color: #555; // Default color
  position: relative;
  width: 150px;
  height: 150px;
}

.gaugebox .content .percent::before {
  content: attr(data-text);
  position: absolute;
  inset: 20px;
  background: #555;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.25rem; // Adjusted font size
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.gaugebox .content .percent svg {
  position: relative;
  width: 150px;
  height: 150px;
  transform: rotate(270deg);
}

.gaugebox .content .percent svg circle {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 3;
  stroke: rgba(0,0,0,0.05);
  transform: translate(5px, 5px);
}

.gaugebox .content .percent svg circle:nth-child(2) {
  stroke: var(--color);
  stroke-dasharray: 440;
  stroke-dashoffset: calc(440 - (440 * var(--num)) / 100);
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  animation-delay: 2.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.gaugebox .content .percent .dot {
  position: absolute;
  inset: 5px;
  z-index: -1;
  animation: animateDot 2s linear forwards;
}

@keyframes animateDot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(calc(3.6deg * var(--num)));
  }
}

.gaugebox .content .percent .dot::before {
  content: '';
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  background: var(--color);
  border-radius: 50%;
}

.gaugebox .content .number {
  position: relative;
  inset: 0;
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  animation-delay: 2.5s;
}

.gaugebox .content .number h2 {
  font-size: 2.5rem;
  color: #555;
}

.gaugebox .content .number h2 span {
  font-weight: 300;
  font-size: 1.5rem;
}

@media (max-width: 600px) {
    .gaugebox {
        width: 270px;
        height: 250px;
    }
}
