.settings-container {
    background: #cecfcf;
    padding: 20px;
    border-radius: 15px;
    margin: 10px 10px 100px 10px;
    box-shadow: -8px -8px 15px #ffffff,
                8px 8px 15px rgba(0, 0, 0, 0.1);
    .slider-container {
      margin: 20px 0;
      label {
        display: block;
        margin-bottom: 10px;
        color: #666;
      }
      input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background: #ddd;
        outline: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
        cursor: pointer;
        &:hover {
          background: #ccc;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #ecf0f3;
          box-shadow: -2px -2px 5px #ffffff,
                      2px 2px 5px rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
        &::-moz-range-thumb {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #ecf0f3;
          box-shadow: -2px -2px 5px #ffffff,
                      2px 2px 5px rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }
    }
    button {
      border: none;
      outline: none;
      background: #ecf0f3;
      color: #666;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: -2px -2px 5px #ffffff,
                  2px 2px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover {
        background-color: #ddd;
      }
    }
  }
  

/* Responsive styles */
@media (max-width: 768px) {
    h1 {
        font-size: 24px;
    }

    label {
        font-size: 18px;
    }

    input[type="number"] {
        padding: 12px;
    }
}
